/*  slider css */

.top-slider {
    /* font-family: Montserrat; */
    margin-top: 5px;
    font-family: "Commissioner", sans-serif;
    text-align: center;
    color: #006600;
    font-size: 21px;
    padding-top: 0vh;
    overflow: hidden;
}

.top-slider-div  {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.top-slider-div:first-of-type {
    animation: appear 6s infinite;
}

.top-slider-div:last-of-type {
    animation: reveal 6s infinite;
}

.top-slider-div:last-of-type span {
    font-size:20px;
    color: var(--orange-color);
    animation: slide 6s infinite;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slide {
    0% {
        margin-left: -800px;
    }

    20% {
        margin-left: -800px;
    }

    35% {
        margin-left: 0px;
    }

    100% {
        margin-left: 0px;
    }
}

@keyframes reveal {
    0% {
        opacity: 0;
        width: 0px;
    }

    20% {
        opacity: 1;
        width: 0px;
    }

    30% {
        width: 290px;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        width: 290px;
    }
}
/* ********************* */

.font-family-class {
    font-family: "Commissioner", sans-serif;
}

.contact-items-location {
    padding: 10px 0 0 5px;
    border: 2px solid rgb(222, 222, 222);
    background-color: rgb(238, 238, 238);
    width: 40%;
    margin: 10px 0;
}

.navbar-main-div{
    background-color: var(--green-color);
  }

.contact-icons {
    width: 30px;
    height: 30px;
}

.item-text {
    padding: 0 0 0 30px;
}

.service-div {
    display: flex;
    margin: 0 0 10% 0;
}

.text-item {
    padding: 0 20px;
}

#tsparticles {
    position: absolute;
    margin-top: 22px;
    width: 100%;
    height: 50vh;
    z-index: 10;
}

.service-detail-v1 {
    margin: 1% 15%;
    flex-wrap: wrap;
}

.cards{
    /* display: grid; */
    /* grid-template-columns: auto auto auto; */
    margin: 0 15%;

}
.card-title {
    width: 300px;
    height: 50px;
    background: black;
    text-align: center;
    color: White;
    margin: auto;
    border-radius: 0 0 10px 10px;
    padding: 10px;
}

.card-items {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 20px auto;
    border-radius: 10px;
    position: relative;
    top: 0;
    transition: top ease 0.5s;
}

.card-items:hover {
    box-shadow: 0px -25px 20px -20px var(--green-color), 25px 0 20px -20px var(--green-color), 0px 25px 20px -20px var(--green-color), -25px 0 20px -20px var(--green-color);
    top: -10px;
}
.hr-for-indi-flag{
    margin: 2px 15%;
}

.first-add {
    border: 2px sloid black;
    /* display: block; */
    height: 30%;
    width: 100%;
    background-color: #0e8201;
}